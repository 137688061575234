//枣阳套溯源配置文件
export default () => {
  let customerConfig = null;
  const hrefInclude = (strArr) => (strArr.filter(str => window.location.href.indexOf(str) >= 0).length > 0);
  if (hrefInclude(['http://jspc', 'https://jspc', 'http://zytsy', 'https://zytsy'])) {
    const userCenterSite = 'http://jspc_login.jgwcjm.com';
    customerConfig = {
      //
      domain: '.jgwcjm.com',
      // 企业中心地址
      orgSite: 'http://jspc_org.jgwcjm.com',
      // 用户中心地址
      userCenterSite: userCenterSite,
      // 用户中心登录页
      userCenterLogin: `${userCenterSite}/#/user/login`,
      // 系统中心地址
      systemSite: 'http://zytsy.jgwcjm.com',
      // 平台中心地址
      platformSite: 'http://jspc_platform.jgwcjm.com',
      // 新用户注册
      register: `${userCenterSite}/#/user/register`,
      // 单点登录iframe的src
      ssoSrc: `${userCenterSite}/#/frame/user/login`,
      // 图片上传返回值
      fileDomain: 'http://file.cjm.so',
      // 溯源模版1
      previewWarp: 'http://jspc_trace_h5.jgwcjm.com',
      // 溯源模版2
      traceH5: 'http://jspc_trace2_h5.jgwcjm.com/',
    };
  }
  return customerConfig;
}
